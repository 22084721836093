@charset "UTF-8";
@import "src/assets/sass/utils/var";

/*components/modal*/

.Modal {
    position: relative;
    top: 50%;
    left: 0;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(1, 1, 1, 0.15);
    transform: translateY(-50%);
    margin: 0 auto;
    z-index: 1000;
    animation: zoomIn 0.2s linear 1;

    &.hide {
        display: none;
    }

    &-wrap {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        overflow: auto;
        background: rgba(#000, 0.7);
    }

    &-header {
        width: 100%;
        position: relative;
        padding: 13px 14px 10px;
        border-bottom: 1px solid #e8e8e8;
    }

    &-title {
        font-size: 16px;
        font-weight: 500;
        color: #000;
    }

    &-body {
        padding: 20px;
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 30px 30px;

        .btn__default {
            width: 100px;
            height: 50px;
            margin: 0 5px;
            border-radius: 25px;
            border: 1px solid #2d2d2f;
            background: #121214;
            font-size: 18px;

            &:not(:disabled):hover {
                background: $primary-color;
                border-color: $primary-color;
            }
        }
    }
}

.btn__ModalClose {
    width: 33px;
    height: 33px;
    position: absolute;
    top: -57px;
    right: 0;
    z-index: 1;

    svg {
        width: 100%;
        height: 100%;
        fill: #d1d2d9 !important;
    }
}

@keyframes zoomIn {
    from {
        transform: translate(0, -50%) scale(0);
    }
    to {
        transform: translate(0, -50%) scale(1);
    }
}
