@charset "UTF-8";
/*base/typography*/

//font-family: 'Noto Sans KR', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

//font-family: 'Poppins', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

//font-family: 'SUIT', sans-serif;
@import url("https://cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");

//font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

//font-family:"Century Gothic", sans-serif;
@font-face {
    font-family: "Century Gothic", sans-serif;
    font-style: normal;
    src: url(../../fonts/CenturyGothic/CenturyGothic.otf) format("opentype"), url(../../fonts/CenturyGothic/CenturyGothicSB.otf) format("opentype"),
        url(../../fonts/CenturyGothic/CenturyGothicB.otf) format("opentype");
}

//font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
@import url("//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css");
