@charset "UTF-8";

.translation-wrap {
    position: relative;
    margin-left: 30px;

    .translation-text {
        color: #c4c4c4;
        font-family: "SUIT", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.84px;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            width: 18px;
            height: 18px;
            stroke: #c4c4c4;
            fill: transparent;
            margin-right: 8px;
            background: transparent;
        }

        &:not(.active):hover {
            color: $primary-color;
            transition: 0.3s;

            svg {
                stroke: $primary-color;
                transition: 0.3s;
            }
        }
    }

    .translation-list {
        min-width: 145px;
        position: absolute;
        left: -50px;
        top: calc(100% + 17px);
        border: 1px solid #464646;
        background: #000;

        > li {
            height: 45px;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.25;
            border-bottom: 1px solid #464646;
            padding: 15px 15px 15px 36px;
            position: relative;
            display: flex;
            align-items: center;
            transition: 0.2s;

            svg {
                width: 14px;
                height: 10px;
                position: absolute;
                left: 12px;
                fill: $primary-color !important;
                display: none;
            }

            &:hover {
                color: $primary-color;
                cursor: pointer;
            }

            &.active {
                color: $primary-color;

                svg {
                    display: block;
                }
            }
        }
    }
}
