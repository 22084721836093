@charset "UTF-8";
@import "src/assets/sass/utils/var";

/*components/input*/

.f__input {
    width: 100%;
    padding: 16px;
    border: 1px solid #2d2d2f;
    background: #121214;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

    &.light {
        height: 48px;
        border-color: #ddd;
        background: #fff;
        border-radius: 4px;
        color: #666;
        padding: 16px 12px;

        &::placeholder {
            color: #666;
            font-weight: 300;
        }
    }

    &::placeholder {
        font-family: inherit;
        font-size: inherit;
        font-weight: 500;
    }

    &:not(:disabled):not([readonly]):hover,
    &:not(:disabled):not([readonly]):focus {
        border-color: $primary-color !important;
    }

    &:not(:disabled):not([readonly]):focus {
        -webkit-box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
    }

    &[readonly] {
        border-color: #d7dde4;
        background-color: #f7f7f7;
        color: #bbb;
        cursor: default;
    }
}

.f__search {
    display: inline-flex;
    align-items: center;
    background: #fff;

    input {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        color: #333;

        &::placeholder {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #666;
        }
    }

    .btn__search {
        height: 100%;

        &:not(:disabled):hover svg {
            fill: $primary-color;
        }
    }
}
