@charset "UTF-8";
/*component/accordion*/

.accordion {
  width: 100%;

  @media screen and (max-width: 1200px) {
    max-width: 100% !important;
  }

  &-item {
    position: relative;
    padding: 14px 30px 20px;
    cursor: pointer;
    transition: 0.2s;

    @media all and (max-width: 1200px) {
      padding: 20px 40px;
    }


    &:not(:first-child) {
      border-top: 1px solid #333;
    }

    &:before {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      top: -1px;
      left: 0;
      background: #b88b5a;
      opacity: 0;
    }

    &.active {
      padding-bottom: 30px;
      border-radius: 0 0 10px 10px;
      background: #fff;
      border-color: transparent;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12);

      + .accordion-item {
        border-color: transparent !important;
      }

      .img-wrap.mob {
        height: 100% !important;
      }

      &:before {
        opacity: 1;
      }

      svg {
        transform: rotate(-90deg);
        fill: #fff;
      }

      .accordion-title {
        font-weight: 600;
      }

      .accordion-content {
        height: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;


    svg {
      width: 10px;
      transform: rotate(90deg);
      fill: #a5a5aa;
      transition: 0.2s;

      @media all and (max-width: 1200px) {
        width: 22px;
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.05em;
    color: #999;

    @media all and (max-width: 1200px) {
      font-size: calc(16px + (24 - 16) * ((100vw - 321px) / (1200 - 321)));
    }
    @media all and (max-width: 767px) {
      font-size: calc(16px + (20 - 16) * ((100vw - 321px) / (767 - 321)));
    }
  }

  &-content {
    height: 0;
    word-break: keep-all;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.05em;
    line-height: 1.56;
    color: #999;
    opacity: 0;
    visibility: hidden;

    @media all and (max-width: 767px) {
      font-size: calc(14px + (18 - 14) * ((100vw - 321px) / (767 - 321)));
    }
  }
}
