@charset "UTF-8";
@import "src/assets/sass/utils/mixin";

/*components/textarea*/

.f__textarea {
    @include customScroll(scroll-y);

    width: 100%;
    min-height: 150px;
    resize: none;
    padding: 16px;
    background: #121214;
    border: 1px solid #2d2d2f;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    word-break: keep-all;
    cursor: text;

    &.light {
        border-color: #ddd;
        background: #fff;
        border-radius: 4px;
        color: #666;
        padding: 12px;

        &::placeholder {
            color: #666;
            font-weight: 300;
        }
    }

    &-amount {
        padding-top: 6px;
        text-align: right;
        font-size: 14px;
        font-weight: 300;
        color: #999;
    }


    &::placeholder {
        font-size: 14px;
        font-weight: 500;
    }


    //-- hover, focus
    &:not(:disabled):not([readonly]):hover,
    &:not(:disabled):not([readonly]):focus {
        border-color: $primary-color !important;
    }

    &:not(:disabled):not([readonly]):focus {
        -webkit-box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
    }
}

