@charset "UTF-8";
/*base/base*/

html,
body {
    width: 100%;
    height: 100vh;
}

body {
    background: #000;
    font-family: "Spoqa Han Sans", "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: 700;

    * {
        box-sizing: border-box;
    }

    button {
        font-family: "Noto Sans KR", sans-serif;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .row {
        display: flex;
        align-items: center;
    }

    .col {
        display: flex;
        flex-flow: column;
    }
}

#root {
    width: 100%;
    height: 100vh;
}
