@charset "UTF-8";
/*components/radio*/

.f__radio {
    height: 18px;
    position: relative;
    display: block;
    padding-left: 24px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.025em;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    &:hover .f__radio-check {
        box-shadow: none;
    }

    &-label {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.025em;
        color: #999;
        line-height: 1.66;
    }
}

/* Hide the browser"s default radio button */
.f__radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.f__radio-check {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2px;
    border-radius: 50%;
    border: solid 1px #d7d7d7;
    transition: 0.2s;
}

/* On mouse-over, add a grey background color */
.f__radio:hover input ~ .f__radio-check {
    border-color: $primary-color;
}

/* When the radio button is checked, add a blue background */
.f__radio input:checked ~ .f__radio-check {
    background-color: #fff;
    border-color: $primary-color;
}

.f__radio input:checked ~ .f__radio-label {
    color: $primary-color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.f__radio-check:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.f__radio input:checked ~ .f__radio-check:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.f__radio .f__radio-check:after {
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: $primary-color;
    transform: translate(-50%, -50%);
}
