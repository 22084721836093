@charset "UTF-8";
@import "../utils/var";
/*vendors-extensions/customSlick*/

/* Arrows */
.slick-prev,
.slick-next {
    width: 11px;
    height: 20px;
    z-index: 999;

    svg {
        fill: #fff;
    }

    &:hover svg {
        fill: $primary-color;
    }
}

.slick-prev {
    left: -12px;
}

.slick-next {
    right: -12px;
}

.slick-prev:before,
.slick-next:before {
    content: "" !important;
}

/* Dots */
.slick-dots {
    bottom: 0;

    li {
        margin: 0;
    }
}
.slick-dots li button:before {
    font-size: 12px;
    color: #3d3d3d;
    opacity: 1;
}
.slick-dots li.slick-active button:before {
    color: #c48f56;
}

/* Slider */
.slick-list,
.slick-track {
    height: 100%;
}

.slick-slide div {
    height: 100%;
    outline: 0;
}
