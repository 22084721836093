@charset "UTF-8";
@import "../../../assets/sass/utils/var";
/*component/button*/

$btn-type: default, solid, moreArrow, dashed;

$btn-color: (
    primary: $primary-color,
    white: #fff,
);

@each $type in $btn-type {
    .btn__#{$type} {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "SUIT", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.025em;
        transition: 0.2s;

        @each $name, $hex in $btn-color {
            &[color="#{$name}"] {
                color: #fff;
                background: $hex;
                border-color: $hex;

                &:not(:disabled):hover {
                    background: darken($hex, 10%);
                    border-color: darken($hex, 10%);
                }
            }
        }

        @if $type == default {
            background: transparent;
            border: 1px solid $primary-color;
            color: #fff;

            svg {
                fill: #fff;
            }

            &:not(:disabled):not([color]):hover {
                background: $primary-color;
            }
        } @else if $type == solid {
            color: #fff;
            background: #121214;
            border: 1px solid #2d2d2f;
            font-size: 18px;

            &:not(:disabled):not([color]):hover {
                //background: darken($primary-color, 4%);
                background: darken(#272223, 4%);
                color: #c48f56;
                border: 1px solid #c48f56;
            }
        } @else if $type == moreArrow {
            width: 100%;
            height: 40px;
            margin-top: 15px;
            border-radius: 2px;
            background: #f0f3f5;
            border: 1px solid #b3b8bf;
            font-family: "NanumSquare", sans-serif;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.05em;
            color: #000;

            &:not(:disabled):hover {
                border-color: $primary-color;
                color: $primary-color;

                svg {
                    fill: $primary-color;
                }
            }

            svg {
                width: 6px;
                height: 12px;
                margin-left: 8px;
                fill: #000;
            }
        }

        @if $type == dashed {
            background: #fff;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            border: 1px dashed #d7d7d7;
            font-weight: 500;
            color: #bbb;

            &:not(:disabled):not([color]):hover {
                color: $primary-color;
                border-color: $primary-color;

                svg {
                    fill: $primary-color;
                }
            }

            svg {
                width: 11px;
                height: 11px;
                margin-right: 8px;
                fill: #d7d7d7;
            }
        }

        &:disabled {
            cursor: not-allowed;
            background: #ccc;
            border-color: #ccc;
            color: #fff;

            svg {
                fill: #fff;
            }
        }
    }
}

//점3개 드롭다운 버튼
.btn__dot {
    span {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #ccc;
        transition: 0.2s;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
        span {
            background: $primary-color;
        }
    }

    &.vertical {
        padding: 5px 10px;

        span {
            display: block;

            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }
    }

    &.horizon {
        padding: 10px 5px;

        span {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 3px;
            }
        }
    }
}
