@charset "UTF-8";
/*components/timeline*/

.Timeline {
  width: 100%;
  min-width: 768px;
  display: flex;
  flex-flow: column-reverse;
  padding: 16px 10px;

  @media all and (max-width: 767px) {
    width: auto;
    min-width: calc(100% - 430px);
    padding: 16px 40px;
  }

  .Timeline-item:last-child {
    .text {
      text-indent: -1em;
      margin-left: 1em;
    }
  }

  .line {
    height: calc(100% - 12px);
    position: absolute;
    top: 16px;
    left: 50%;
    border-left: 2px solid #4f4840;
    opacity: 0;
    transform: translateX(-50%);

    @media all and (max-width: 767px) {
      left: -15%;
    }
  }

  .circle {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 4px;
    left: 50%;
    border-radius: 50%;
    background: #4f4840;
    border: 2px solid #4f4840;
    transform: translateX(-50%);

    @media all and (max-width: 767px) {
      left: -15%;
    }
  }

  .year {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.035em;
    color: #7d7164;

    > .end-year:before {
      content: "~";
      margin: 0 4px;
    }
  }

  .content {
    padding-top: 10px;

    p {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.025em;
      line-height: 1.43;
    }
  }

  &-item {
    width: 100%;
    position: relative;
    padding-bottom: 30px;

    @media all and (max-width: 767px) {
      padding-bottom: 60px;
    }

    &:not(:first-child) .line {
      opacity: 1;
    }

    &:last-child .circle {
      width: 20px;
      height: 20px;
      background: #121212;
      border: 4px solid #fff;
    }

    &:nth-child(2n) .Timeline-content {
      margin: 0 0 0 auto;
      text-align: left;
    }
  }

  @media all and (min-width: 768px) {
    &-content {
      width: 50%;
      margin: 0 auto 0 0;
      padding: 0 20px;
      text-align: right;
    }
  }
}
