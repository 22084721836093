@charset "UTF-8";
/*components/form*/

.f__row {
    display: flex;
}

.f__col {
    display: flex;
    flex-flow: column;
}

.f__group {
    width: 100%;
    margin-bottom: 30px;
    .f__label {
        margin-bottom: 5px;
    }
}

.f__inputGroup {
    display: flex;
    align-items: center;

    .btn__delete {
        height: 35px;

        &:not(:disabled):hover svg {
            fill: $primary-color;
        }

        svg {
            width: 16px;
            height: 16px;
            fill: #999;
        }
    }
}

.f__label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.14;
    color: #999;

    &.required::after {
        content: "\002A";
        padding-top: 5px;
        padding-left: 2px;
        color: #c48f56;
    }
}

.f__unit {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #bbb;
}

.has-error {
    height: auto;

    .f__input,
    .f__textarea {
        border-color: #ff0000 !important;
        box-shadow: none !important;
    }

    .f__feedback {
        height: 18px !important;
        opacity: 0;
        animation-name: validation;
        animation-duration: 0.3s;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-direction: alternate;
        animation-fill-mode: forwards;
    }
}

.f__feedback {
    font-size: 12px;
    letter-spacing: -0.025em;
    color: #ff0000;
    opacity: 0;
    height: 0;
    display: block;
    width: 100%;
    line-height: 1.5;
}

@keyframes validation {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 7px;
    }
}
