@charset "UTF-8";
@import "src/assets/sass/utils/var";

.c__select {
  position: relative;

  .select-item {
    width: 100%;
    height: 48px;
    border: 1px solid #ddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 0 20px 0 12px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    color: #666;
    font-weight: 300;

    &:not(.disabled):hover:not(.readonly):hover,
    &:not(.disabled).active:not(.readonly).active {
      border-color: $primary-color;

      svg {
        fill: $primary-color;
      }
    }

    &.disabled,
    &.readonly {
      background: #fafafa;
      border-color: #e8e8e8;
      cursor: not-allowed;
    }

    span {
      transition: 0.3s;
    }

    .placeholder {
      font: inherit;
    }

    .arrow-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: rgb(51, 51, 51);
        transition: all 0.3s;
      }

      &.active {
        transform: rotate(-180deg);
      }
    }
  }

  .select-dropdown {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    top: calc(100% + 4px);
    animation: slideUpIn 0.2s;
    z-index: 2;
    box-sizing: border-box;


    @keyframes slideUpIn {
      0% {
        transform: scaleY(0.8);
        transform-origin: 0 0;
        opacity: 0;
      }
      100% {
        transform: scaleY(1);
        transform-origin: 0 0;
        opacity: 1;
      }
    }
  }

  .select-dropdown-item {
    width: 100%;
    height: 30px;
    padding: 0 8px 0 12px;
    word-break: break-all;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    transition: 0.3s;

    &.disabled {
      color: #bbb;
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      background: #edf0fd;
    }
  }
}